import React from 'react';
import { Link } from 'react-router-dom';
import NghtlvsFooter from '../components/footer';
import textLogo from '../img/TextLogoYellow.png';

const Register = () => {
    return (
        <div className='App'>
            <div class='registerContainer'>
                <div className='hero'>
                    <img src={textLogo} alt=''/>
                </div>
                <div className='regCard'>  
                <h1>JOIN THE SLEEPLESS CLUB</h1>
                    <form>
                        <div className='input-fields'>
                            <input type='text' id='name' name='name' placeholder='YOUR FULL NAME'></input>
                            <input type='email' id='emailAddress' name='emailAddress' placeholder='EMAIL ADDRESS' required></input>
                            <input type='password' id='password' name='password' placeholder='PASSWORD' required></input>
                            <input type='password' id='passwordConfirm' name='passwordConfirm' placeholder='CONFIRM PASSWORD' required></input>
                            <button type='submit' action='' method='post'><Link to='#'><b>REGISTER</b></Link></button>
                        </div>
                        <div className='termsAndConditions'>
                                <input name='ts&cs' id='ts&cs' type='checkbox'></input>
                                <label for='ts&cs'>BORING TS&CS STUFF. I CONSENT TO GIVE ALL MY DATA TO ARWEL. HE MIGHT SELL IT TO CHINA.</label>
                        </div>
                    </form>
                </div>
            </div>
            <footer>
                <NghtlvsFooter />
            </footer>
        </div> 
    );
}

export default Register;
