import React from 'react';
import textLogo from '../img/NGHTLVS_LINE.svg';
import YoutubeEmbedSingle from '../components/YoutubeEmbedSingle';
import NghtlvsFooter from '../components/footer';

const Home = () => {
    return (
        <div className='HomePageContainer'>
            <div className='hero'>
                <img src={ textLogo } alt=''/>
            </div>
            <div className='genericCard'>
                <YoutubeEmbedSingle embedId='dv-shaAiN6M'/>
            </div>
            <footer>
                <NghtlvsFooter />
            </footer>
        </div>
    );
}

export default Home;
