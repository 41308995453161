import React from "react";
import NghtlvsFooter from "../components/footer";
import eyecon from '../img/NightlivesLogoPink.png';

const ComingSoon = () => {
    return (
        <div className='App'>
            <div className='coming-soon'>
                <img src={ eyecon } alt='' />
                <h1>COMING SOON</h1>
            </div>
            <footer>
                <NghtlvsFooter />
            </footer>
        </div>
    );
}

export default ComingSoon;
