import React from 'react';
const MusicPlayer = props => {
    return (
        <div className='music-player'>
            <iframe title="songwhip" id="songwhip" className="songwhip" src="https://songwhip.com/nightlives/the-sleepless-club" />
        </div>
    );
}

export default MusicPlayer;
