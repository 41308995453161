import React, { Component } from "react";
import eyecon from '../img/NightlivesLogoPink.png';
import { Link } from 'react-router-dom';


export default class Nav extends Component
{
    render() {
        return (
            <nav>
                <Link to='/'><img src={ eyecon } alt='' /> </Link>
                <ul className='nghtlvsNav'>
                    <li><Link to='/'>HOME</Link></li>
                    <li><Link to='/music'>MUSIC</Link></li>
                    <li><Link to='/coming-soon'>SHOP</Link></li>
                </ul>
                <ul className='login-register'>
                    <li><Link to='/coming-soon'>LOGIN</Link></li>
                    <li><Link to='/coming-soon'>REGISTER</Link></li>
                </ul>
            </nav>
        );
    }
}

