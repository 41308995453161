import React from "react";
import NghtlvsFooter from "../components/footer";
import eyecon from '../img/NightlivesLogoYellow.png';

const FourOhFour = () => {
    return (
        <div className='App'>
            <div className='FourOhFour'>
                <img src={ eyecon } alt='' />
                <h1>PAGE NOT FOUND</h1>
            </div>
            <footer>
                <NghtlvsFooter />
            </footer>
        </div>
        
    );
}

export default FourOhFour;
