import React from 'react';
import Facebook from '../img/facebook.png';
import BirdApp from '../img/bird-app.png';
import Tiktok from '../img/tiktok.png';
import Insta from '../img/insta.png';
import Youtube from '../img/youtube.png';

const NghtlvsFooter = () => {
    return (
        <div>
            <a href='https://twitter.com/nghtlvs'><img src={ BirdApp } alt='Horrid Bird App'/></a>
            <a href='https://www.instagram.com/nghtlvs/'><img src={ Insta } alt='Instagram'/></a>
            <a href='https://vm.tiktok.com/ZMNLNAJwf/'><img src={ Tiktok } alt='TikTok'/></a>
            <a href='https://www.facebook.com/NGHTLVS'><img src={ Facebook } alt='Facebook'/></a>
            <a href='https://www.youtube.com/c/nghtlvs'><img src={ Youtube } alt='YouTube'/></a>
        </div>
    );
}

export default NghtlvsFooter;
