import React from 'react';
import NghtlvsFooter from '../components/footer';

const Login = () => {
    return (
        <div className='App'>
            <h1>This is the Login page</h1>
            <footer>
                <NghtlvsFooter />
            </footer>
        </div> 
    );
}

export default Login;
