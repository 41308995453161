import React from 'react';
import NghtlvsFooter from '../components/footer';

const Shop = () => {
    return (
        <div>
            <h1>Shop</h1>  
            <footer>
                <NghtlvsFooter />
            </footer>
        </div>
    );
}

export default Shop;
