import React from 'react';
import PropTypes from 'prop-types';

const YoutubeEmbedSingle = ({ embedId }) => (
    <iframe
    src={`https://www.youtube.com/embed/${embedId}`}
    frameBorder='0'
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture'
    allowFullScreen
    title={ embedId }
    />
);

YoutubeEmbedSingle.propTypes = {
    embedId: PropTypes.string.isRequired
}

export default YoutubeEmbedSingle;
