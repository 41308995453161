import React from 'react';
import NghtlvsFooter from '../components/footer';
import MusicPlayer from '../components/musicPlayer.jsx';
import useScript from "../hooks/useScript";

const Music = () => {
    return (
        <div className='App'>
            <div className='musicPlayer'>
                <MusicPlayer />
            </div>
            <footer>
                <NghtlvsFooter />
            </footer>
        </div>
    );
}

export default Music;
